import { EndpointsConstant } from 'app/core/constants/endpoint.constant';
import { VehicleOrderConstantMock } from './vehicle-order.constant.mock';

export class VehicleOrderEndpointsConstant {
  static readonly vehicleOrderBase = 'orders';
  static readonly VehicleOrderManagerPath = 'manager';
  static readonly taskboardPath = 'taskboard';
  static config = {
    CREATE: {
      url: `${VehicleOrderEndpointsConstant.vehicleOrderBase}`
    },
    LIST: {
      url: `${VehicleOrderEndpointsConstant.vehicleOrderBase}/manager`
    },
    ITEM: {
      url: `${VehicleOrderEndpointsConstant.vehicleOrderBase}/${VehicleOrderEndpointsConstant.VehicleOrderManagerPath}/${EndpointsConstant.genericIdParamSelector}`
    },
    ORDER_STATUS: {
      url: `${VehicleOrderEndpointsConstant.vehicleOrderBase}/order-status`,
      mock: VehicleOrderConstantMock.orderStatus
    },
    VEHICLE_TYPE: {
      url: `${VehicleOrderEndpointsConstant.vehicleOrderBase}/vehicle-type`,
      mock: VehicleOrderConstantMock.vehicleType
    },
    APPROVE: {
      url: `${VehicleOrderEndpointsConstant.vehicleOrderBase}/${VehicleOrderEndpointsConstant.taskboardPath}/${EndpointsConstant.genericIdParamSelector}/approvals`
    },
    BULK_APPROVE: {
      url: `${VehicleOrderEndpointsConstant.vehicleOrderBase}/taskboard-approvals`
    },
    REJECT: {
      url: `${VehicleOrderEndpointsConstant.vehicleOrderBase}/${VehicleOrderEndpointsConstant.taskboardPath}/${EndpointsConstant.genericIdParamSelector}/rejected`
    },
    DELETE: {
      url: `${VehicleOrderEndpointsConstant.vehicleOrderBase}/${VehicleOrderEndpointsConstant.taskboardPath}/${EndpointsConstant.genericIdParamSelector}`
    }
  };
}
