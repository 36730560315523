import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output
} from '@angular/core';
import { ConfirmDialogProps } from '@components/organisms/confirm-dialog/confirm-dialog.enum';
import { ConfirmServiceService } from '@components/organisms/confirm-dialog/services/confirm-service.service';
import { FilterBaseComponent } from '@components/organisms/next-table/components/filter-base/filter-base.component';
import { GenericTableColumnProps, TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { TbVehicleOrderConstant } from '@pages/task-board/constants/tb-vehicle-order.constant';
import { TaskBoardStatus } from '@pages/task-board/enums/task-board.enum';
import {
  TbVehicleOrderAction,
  TbVehicleOrderProps,
  VehicleOrderTaskboardTestIds
} from '@pages/task-board/enums/tb-vehicle-order.enum';
import { TaskBoardRouteConfig } from '@pages/task-board/task-board.route.config';
import { TbVehicleOrderItemList } from '@pages/task-board/types/tb-vehicle-order.type';
import { NavigationService } from '@services/navigation.service';
import { RouteParams } from 'app/core/enums/route.enum';
import { MenuItemService } from 'app/core/services/menu-item.service';
import { VehicleOrderService } from '@pages/new-business/pages/vehicle-order/services/vehicle-order.service';
import { ToastMsgService } from '@services/toast-msg.service';
import { TaskBoardService } from '@pages/task-board/services/task-board.service';

@Component({
  selector: 'tb-vehicle-order',
  templateUrl: './tb-vehicle-order.component.html',
  styleUrl: './tb-vehicle-order.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TbVehicleOrderComponent extends FilterBaseComponent implements OnInit {
  @Output() vehicleOrderTaskList = new EventEmitter<any>();
  @Output() removeFromListId: EventEmitter<any> = new EventEmitter<any>();

  vehicleOrderList: TbVehicleOrderItemList[];
  tableList: TbVehicleOrderItemList[];
  selectedOrders: any;
  sortField = TbVehicleOrderProps.ORDER_DATE;

  protected readonly TbVehicleOrderProps = TbVehicleOrderProps;
  protected readonly TaskBoardStatus = TaskBoardStatus;
  protected readonly GenericTableColumnProps = GenericTableColumnProps;
  protected readonly TableFieldProps = TableFieldProps;
  protected readonly TbVehicleOrderConstant = TbVehicleOrderConstant;
  protected readonly VehicleOrderTaskboardTestIds = VehicleOrderTaskboardTestIds;

  constructor(
    injector$: Injector,
    private _cdr: ChangeDetectorRef,
    private _menuItemService: MenuItemService,
    private _confirmationService: ConfirmServiceService,
    private _navigationService: NavigationService,
    private _vehicleOrderService: VehicleOrderService,
    private _toastService: ToastMsgService,
    private _taskBoardService: TaskBoardService
  ) {
    super(injector$);
    this._cdr = injector$.get(ChangeDetectorRef);
  }

  ngOnInit() {
    this.applyFilter();
    this._taskBoardService.getVehicleOrderList().subscribe((list: TbVehicleOrderItemList[]) => {
      this.vehicleOrderList = list;
      this.vehicleOrderTaskList.emit(this.vehicleOrderList);
      this._configActionList();
    });
  }

  sortPageChangeHandle(event: any) {
    // TODO Implement
  }

  handleModifyVehicleOrder() {
    console.log('handleModifyVehicleOrder');
  }

  handleCancelVehicleOrderSelection() {
    this.selectedOrders = [];
  }

  handleApproveVehicleOrderSelection() {
    console.log('event', this.selectedOrders)
    this._confirmationService
      .asyncConfirmation({
        [ConfirmDialogProps.TITLE_KEY]: 'CONFIRMATION.APPROVE_TITLE',
        [ConfirmDialogProps.SUMMARY_KEY]: 'CONFIRMATION.APPROVE_SUMMARY',
        [ConfirmDialogProps.CONFIRM_LABEL_KEY]: 'CONFIRMATION.APPROVE_BUTTON',
        [ConfirmDialogProps.CONFIRM_CALLBACK]: () => this._handleApprove(this.selectedOrders),
        [ConfirmDialogProps.HIDE_DISMISS]: true
      })
      .then();
  }

  handleModifyTask(tdVehicleOrder: TbVehicleOrderItemList) {
    this._navigationService
      .navigateToRouteConfig(TaskBoardRouteConfig.vehicleOrderEdit, {
        urlParams: { [RouteParams.ID]: tdVehicleOrder.id }
      })
      .then();
  }

  private _configActionList() {
    this.tableList = this.vehicleOrderList.map((tdVehicleOrder: TbVehicleOrderItemList) => {
      return {
        ...tdVehicleOrder,
        [GenericTableColumnProps.MENU_LIST_PENDING]: this._menuItemService.buildMenuItemList(
          [
            TbVehicleOrderAction.DELETE,
            TbVehicleOrderAction.MODIFY
          ], {
            [TbVehicleOrderAction.DELETE]: {
              ...TbVehicleOrderConstant.actionConfig[TbVehicleOrderAction.DELETE],
              callback: () => this._deleteActionCallback(tdVehicleOrder)
            },
            [TbVehicleOrderAction.MODIFY]: {
              ...TbVehicleOrderConstant.actionConfig[TbVehicleOrderAction.MODIFY],
              callback: () => this._modifyActionCallback(tdVehicleOrder)
            }
          }
        ),
        [GenericTableColumnProps.MENU_LIST_WAITING]:this._menuItemService.buildMenuItemList(
          [
            TbVehicleOrderAction.APPROVE,
            TbVehicleOrderAction.REJECT,
            TbVehicleOrderAction.EXCEPTION
          ], {
            [TbVehicleOrderAction.REJECT]: {
              ...TbVehicleOrderConstant.actionConfig[TbVehicleOrderAction.REJECT],
              callback: () => this._rejectActionCallback(tdVehicleOrder)
            },
            [TbVehicleOrderAction.APPROVE]: {
              ...TbVehicleOrderConstant.actionConfig[TbVehicleOrderAction.APPROVE],
              callback: () => this.approveActionCallback(tdVehicleOrder)
            },
            [TbVehicleOrderAction.EXCEPTION]: {
              ...TbVehicleOrderConstant.actionConfig[TbVehicleOrderAction.EXCEPTION]
            }
          }
        ),
        [GenericTableColumnProps.MENU_LIST_REJECTED]: this._menuItemService.buildMenuItemList(
          [
            TbVehicleOrderAction.DELETE,
            TbVehicleOrderAction.MODIFY
          ], {
            [TbVehicleOrderAction.DELETE]: {
              ...TbVehicleOrderConstant.actionConfig[TbVehicleOrderAction.DELETE],
              callback: () => this._deleteActionCallback(tdVehicleOrder)
            },
            [TbVehicleOrderAction.MODIFY]: {
              ...TbVehicleOrderConstant.actionConfig[TbVehicleOrderAction.MODIFY],
              callback: () => this._modifyActionCallback(tdVehicleOrder)
            }
          }
        )
      };
    });
  }

  private _deleteActionCallback(tdVehicleOrder: TbVehicleOrderItemList) {
    this._confirmationService
      .asyncConfirmation({
        [ConfirmDialogProps.TITLE_KEY]: 'CONFIRMATION.DELETE_TITLE',
        [ConfirmDialogProps.SUMMARY_KEY]: 'CONFIRMATION.DELETE_SUMMARY',
        [ConfirmDialogProps.CONFIRM_LABEL_KEY]: 'CONFIRMATION.DELETE_BUTTON',
        [ConfirmDialogProps.CONFIRM_CALLBACK]: () => this._handleDelete(tdVehicleOrder),
        [ConfirmDialogProps.HIDE_DISMISS]: true
      })
      .then();
  }

  private _rejectActionCallback(tdVehicleOrder: TbVehicleOrderItemList) {
    this._confirmationService
      .asyncConfirmation({
        [ConfirmDialogProps.TITLE_KEY]: 'CONFIRMATION.REJECT_TITLE',
        [ConfirmDialogProps.SUMMARY_KEY]: 'CONFIRMATION.REJECT_SUMMARY',
        [ConfirmDialogProps.CONFIRM_LABEL_KEY]: 'CONFIRMATION.REJECT_BUTTON',
        [ConfirmDialogProps.CONFIRM_CALLBACK]: () => this._handleReject(tdVehicleOrder),
        [ConfirmDialogProps.HIDE_DISMISS]: true
      })
      .then();
  }
  private approveActionCallback(tdVehicleOrder: TbVehicleOrderItemList) {
    this._confirmationService
      .asyncConfirmation({
        [ConfirmDialogProps.TITLE_KEY]: 'CONFIRMATION.APPROVE_TITLE',
        [ConfirmDialogProps.SUMMARY_KEY]: 'CONFIRMATION.APPROVE_SUMMARY',
        [ConfirmDialogProps.CONFIRM_LABEL_KEY]: 'CONFIRMATION.APPROVE_BUTTON',
        [ConfirmDialogProps.CONFIRM_CALLBACK]: () => this._handleApprove(tdVehicleOrder),
        [ConfirmDialogProps.HIDE_DISMISS]: true
      })
      .then();
  }

  private _modifyActionCallback(tdVehicleOrder: TbVehicleOrderItemList) {
    this.handleModifyTask(tdVehicleOrder);
  }

  private _handleDelete(tdVehicleOrder: TbVehicleOrderItemList) {
    this._vehicleOrderService.deleteOrder(tdVehicleOrder[TbVehicleOrderProps.ID]).subscribe({
      next: () => {
        this._toastService.success('TASK_BOARD.CONFIRMATION.DELETED', {
          id: tdVehicleOrder[TbVehicleOrderProps.ID]
        });
        
        this.tableList = this.tableList.filter(item => item.id !== tdVehicleOrder[TbVehicleOrderProps.ID])

        this._cdr.markForCheck();
        this.selectedOrders = [];
      },
      error: () => {
        this._toastService.error('TASK_BOARD.CONFIRMATION.ERROR_DELETED');
      }
    })
  }

  private _handleReject(tdVehicleOrder: TbVehicleOrderItemList) {
    this._vehicleOrderService.rejectOrder(tdVehicleOrder[TbVehicleOrderProps.ID]).subscribe({
      next: () => {
        this._toastService.success('TASK_BOARD.CONFIRMATION.REJECTED', {
          id: tdVehicleOrder[TbVehicleOrderProps.ID]
        });
        const index = this.tableList.findIndex(task => task[TbVehicleOrderProps.ID] === tdVehicleOrder[TbVehicleOrderProps.ID]);
        const vehicleOrder = this.tableList[index];
        if (index >= 0) {
          this.tableList[index][TbVehicleOrderProps.TASK_STATUS] = TaskBoardStatus.REJECTED;
          this.tableList.splice(index, 1);
          this.tableList.unshift(vehicleOrder)
        }
        this._cdr.markForCheck();
        this.selectedOrders = [];
      },
      error: () => {
        this._toastService.error('TASK_BOARD.CONFIRMATION.ERROR_REJECTED');
      }
    })
  }


  private _handleApprove(tdVehicleOrder: TbVehicleOrderItemList | TbVehicleOrderItemList[]) {
    if (Array.isArray(tdVehicleOrder)) {
      const ordersBody: any = [];
      tdVehicleOrder.forEach((item: TbVehicleOrderItemList) => {
        ordersBody.push ({
          id: item.id,
          status: item.taskStatus
        })
      });
      this._handleApproveItems(ordersBody);
    } else {
      this._handleApproveItem(tdVehicleOrder);
    }
    this.selectedOrders = [];
  }

  private _handleApproveItems(tdVehicleOrders: any) {

    this._vehicleOrderService.approveOrders(tdVehicleOrders).subscribe({
      next: () => {
        tdVehicleOrders.forEach((order: TbVehicleOrderItemList) => {
          this.tableList = this.tableList.filter(item => item.id !== order[TbVehicleOrderProps.ID])
          this._toastService.success('TASK_BOARD.CONFIRMATION.APPROVED', {
            id: order[TbVehicleOrderProps.ID]
          });
        })
        
        this._cdr.markForCheck();
      },
      error: () => {
        this._toastService.error('TASK_BOARD.CONFIRMATION.ERROR_APPROVED');
      }
    })
  }
  private _handleApproveItem(tdVehicleOrder: TbVehicleOrderItemList) {
    this._vehicleOrderService.approveOrder(tdVehicleOrder[TbVehicleOrderProps.ID]).subscribe({
      next: () => {
        this._toastService.success('TASK_BOARD.CONFIRMATION.APPROVED', {
          id: tdVehicleOrder[TbVehicleOrderProps.ID]
        });
        this.tableList = this.tableList.filter(item => item.id !== tdVehicleOrder[TbVehicleOrderProps.ID])
        
        this._cdr.markForCheck();
      },
      error: () => {
        this._toastService.error('TASK_BOARD.CONFIRMATION.ERROR_APPROVED');
      }
    })
  }
}