import { Injectable } from '@angular/core';
import { BackendService } from '@services/backend.service';
import { VehicleOrderEndpointsConstant } from '@pages/new-business/pages/vehicle-order/constants/vehicle-order.endpoints.constant';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '../../../../../core/types/api.type';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { ApiProps } from '../../../../../core/enums/api.enum';
import { VehicleOrderProps } from '@pages/new-business/pages/vehicle-order/enums/vehicle-order.enum';
import {
  VehicleOrder,
  VehicleOrderAPI,
  VehicleOrderCreateResponse
} from '@pages/new-business/pages/vehicle-order/types/vehicle-order.type';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { RouteParams } from 'app/core/enums/route.enum';
import { HttpConfigProps } from '../../../../../shared/enums/backend.enum';
import { RoutingConfigConstant } from '../../../../../core/constants/routing-config.constant';

@Injectable({
  providedIn: 'root'
})
export class VehicleOrderService {
  constructor(
    private _backendService: BackendService,
    private _dropdownService: DropdownService
  ) {}

  getOrders(): Observable<VehicleOrder[]> {
    return this._backendService.get(VehicleOrderEndpointsConstant.config.LIST).pipe(
      map((result: ApiResponse<VehicleOrder>) => {
        return NextObjectHelper.getPropertyFromObject(result, [ApiProps.RESULT, VehicleOrderProps.ORDERS], []);
      })
    );
  }

  getVehicleOrder(id: number) {
    return this._backendService.get(VehicleOrderEndpointsConstant.config.ITEM, {
      urlParams: { [RouteParams.ID]: id },
      resultWithinProp: [ApiProps.RESULT, 'order']
    });
  }

  getOrderStatusDropdownList() {
    return this._dropdownService.getDropdownTranslationList(VehicleOrderEndpointsConstant.config.ORDER_STATUS, {
      labelProp: ['status'],
      valueProp: ['status']
    });
  }

  getVehicleTypeDropdownList() {
    return this._dropdownService.getDropdownTranslationList(VehicleOrderEndpointsConstant.config.VEHICLE_TYPE, {
      labelProp: ['name'],
      valueProp: ['name']
    });
  }

  createOrder(order: VehicleOrderAPI): Observable<ApiResponse<VehicleOrderCreateResponse>> {
    return this._backendService.post(VehicleOrderEndpointsConstant.config.CREATE, order);
  }

  approveOrder(orderId: string): Observable<ApiResponse<VehicleOrderCreateResponse>> {
    return this._backendService.post(
      VehicleOrderEndpointsConstant.config.APPROVE,
      {},
      {
        [HttpConfigProps.URL_PARAMS]: {
          [RoutingConfigConstant.mainIdParam]: orderId
        }
      }
    );
  }

  approveOrders(orders: any): Observable<ApiResponse<VehicleOrderCreateResponse>> {
    return this._backendService.post(
      VehicleOrderEndpointsConstant.config.BULK_APPROVE,
      orders,
      {}
    );
  }

  rejectOrder(orderId: string): Observable<ApiResponse<VehicleOrderCreateResponse>> {
    return this._backendService.put(
      VehicleOrderEndpointsConstant.config.REJECT,
      {},
      {
        [HttpConfigProps.URL_PARAMS]: {
          [RoutingConfigConstant.mainIdParam]: orderId
        }
      }
    );
  }

  deleteOrder(orderId: string): Observable<ApiResponse<VehicleOrderCreateResponse>> {
    return this._backendService.delete(
      VehicleOrderEndpointsConstant.config.DELETE,
      {},
      {
        [HttpConfigProps.URL_PARAMS]: {
          [RoutingConfigConstant.mainIdParam]: orderId
        }
      }
    );
  }
}
